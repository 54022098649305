.modal {
  position: absolute;
  display: flex;
  z-index: 3;
  top: 48px;
  right: 0;
  left: 0;
  /* background-color: var(--primary-color); */
  margin: auto;
  width: 30%;
  /* height: 300px; */
  border-radius: 4px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 1);
  align-items: center;
  justify-content: space-between;
}

.modal div {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
}

.modal div h1 {
  color: white;
  font-size: 1em;
}

.modal div button {
  width: fit-content;
  border-radius: 8px;
  border: 0;
  outline: 0;
  font-size: 1em;
  font-weight: 400;
  background-color: var(--secondary-color);
  color: rgb(0, 0, 0);
}

/* Media queries  */

@media screen and (max-width: 800px) {
  .modal {
    width: 50%;
  }
}
@media screen and (max-width: 600px) {
  .modal {
    width: 70%;
  }
}
