.main_container {
  display: flex;
  width: 100%;
  background: rgb(255, 255, 255);
  /* border: 2px solid red; */
  height: 100vh;
  /* align-items: flex-start;
  justify-content: center; */
}

.left_container {
  height: 100vh;
  width: 80%;
  border: 2px;
  padding: 9rem 0 0 3rem;
  gap: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.left_top_container {
  width: 100%;
  height: 50%;
}

.expiry_main_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
}

.expiry_button {
  display: flex;
  gap: 1rem;
}

.expiry_button > button {
  padding: 0.5rem 0.5rem;
  font-size: 0.75em;
  border-radius: 8px;
  outline: 0;
  border: 0;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.expiry_container {
  width: 90%;
  height: 90%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  /* gap: 1.25rem; */
}

.expiry_content_container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  /* margin-top: 0.75rem; */
}

.expiry_content_container img {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  /* border: 2px solid red; */
}

.expiry_content {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem 0 0;
}

.main_add_container {
  display: flex;
  gap: 1rem;
}

.add_container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.5rem;
}

.add_container h2 {
  font-size: 1rem;
  color: black;
}

.right_container {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  padding: 9rem 0 0 0;
  height: 100vh;
  width: 50%;
}

.activity_main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
}

.activity_heading {
  display: flex;
  /* width: 100%; */
  justify-content: space-between;
  width: 90%;
  align-items: center;
}
.activity_heading > h1 {
  font-size: 1.5rem;
}

.activity_heading > button {
  font-size: 1rem;
  color: #e84b01;
  outline: 0;
  border: 0;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
}

.activity_desc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  height: 70%;
}

.activity_desc img {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

.activity {
  background-color: #f5f5f5;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  border-radius: 16px;
  width: 90%;
  transition: all 300ms ease;
  /* box-shadow: 0px 0px 4px rgba(240, 52, 52, 0.1); */
}

.activity:hover {
  background-color: rgba(255, 15, 0, 0.22);
}

.date {
  color: #565656;
  font-weight: 200;
}

.heading {
  font-weight: 500;
}

/* Media queries  */

@media screen and (max-width: 1210px) {
  .date {
    font-size: 0.75rem;
  }

  .heading {
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 1000px) {
  .main_container {
    flex-direction: column;
    overflow-y: scroll;
    align-items: center;
    height: 100vh;
    gap: 0rem;
    gap: 4rem;
  }

  .left_top_container {
    height: 80%;
  }

  .expiry_container {
    width: 80%;
    height: 100%;
  }

  .left_container {
    height: 80%;
    padding: 3rem 0 0 0rem;
    align-items: center;
    justify-content: space-between;
    gap: 0rem;
  }

  .expiry_main_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
  }

  .right_container {
    padding: 0rem 0 0 0;
    height: 100%;
    width: 50%;
  }
  .add_container {
    align-items: center;
  }

  .date {
    font-size: 1rem;
  }

  .heading {
    font-size: 1rem;
  }
  .right_container {
    width: 70%;
  }
  .activity_heading {
    width: 100%;
  }
  .activity_desc {
    align-items: center;
  }
  .activity {
    width: 100%;
  }
}

@media screen and (max-width: 760px) {
  .expiry_container {
    width: 100%;
    height: 100%;
  }
  .left_container {
    height: 100%;
  }
}

@media screen and (max-width: 475px) {
  .add_container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: flex-start;
  }

  .add_container > h2 {
    text-align: center;
  }

  .main_add_container {
    display: flex;
    gap: 1rem;
    align-items: flex-end;
  }
}
