.main_container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  gap: 2rem;
  padding: 2rem 0;
  flex: 1;
  padding: 2rem 1rem;
  overflow-y: hidden;
  z-index: 1;
  position: relative;
  background-color: white;
}

.button_heading {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.button_alignment_container {
  display: flex;
  gap: 1rem;
}

.button_heading h2,
.form_input_label h2 {
  font-size: 1.4em;
  font-weight: 600;
  color: white;
}

.form_input_label {
  display: flex;
  /* flex-direction: column; */
  gap: 0rem;
  align-items: center;
}
.form_input_label input {
  outline: 0;
  border: 0;
  height: 40px;
  padding: 0 1rem;
  background-color: white;
  width: 400px;
  border-radius: 16px 0px 0px 16px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}

.table_wrapper_container {
  display: flex;
  align-items: center;
  height: 80%;
  border-radius: 8px;
  gap: 3rem;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  background-color: white;
  padding: 1rem;

  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}

.table_wrapper_container > h1 {
  /* color: rgb(255, 255, 255); */
  position: absolute;
  font-size: 1.5em;
  /* color: var(--primary-color); */
  color: black;
  top: 24px;
}

.table_wrapper_container hr {
  height: 1px;
  border-width: 0;
  width: 100%;
  background-color: #efefef;
  margin-left: auto;
  margin-right: auto;
}

.table_container {
  display: flex;
  flex-direction: column;
  /* gap: 2rem; */
  height: 80%;
  width: 1100px;
  overflow-y: scroll;
  background-color: white;
  padding: 1rem;
  gap: 1rem;
  position: relative;
  border-radius: 8px;
  min-width: 0;
  margin-top: 16px;
  padding: 1rem 2rem;
}

.table_main_container {
  align-self: center;
  /* 
  min-height: 400px;
  max-height: 400px; */
}

.table_main_container button,
.excel_container button {
  position: absolute;
  left: 0;
  bottom: 16px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  padding: 8px 16px;
  border-radius: 8px;
  border: 0;
  outline: 0;
  font-size: 1em;
  font-weight: 400;
  background-color: var(--primary-color);
  color: white;
}

/* For table fields  */

.grid_headings,
.grid_inputs {
  gap: 0rem;
  display: inline-grid;
  grid-template-columns: 200px 200px 200px 200px 200px;
  background-color: white;
}

.grid_inputs {
  padding: 0.75rem 0;
}

.grid_headings span {
  font-size: 1.25em;
  font-weight: 200;
  text-align: center;
  padding: 0.5rem 0;
  color: #606060;
  /* text-align: left; */
}

.heading {
  margin-bottom: 16px;
}

.grid_inputs input {
  width: 100%;
  /* padding: 0.4rem; */
  font-size: 1em;
  font-weight: 400;
  outline: 0;
  border-width: 0;
  text-align: center;
}

/* Modal Drivers  */

.main_modal_container {
  position: absolute;
  display: flex;
  z-index: 3;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 2rem;
  background-color: white;
  box-shadow: 0px 0px 8px rgb(0, 0, 0);
  margin: auto;
  border-radius: 8px;
  width: 700px;
  height: 100vh;
}
/* 
.main_modal_container label,
.main_modal_container p {
  font-size: 0.75em;
} */

.modal_container {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
}

.modal_container > button {
  position: absolute;
  bottom: 8px;
}

.wrap_container {
  overflow-y: scroll;
  padding: 0.5rem 2rem 0.25rem 0rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.driver_licence_container {
  background-color: #d7e7ef;
  border-radius: 16px;
}

.driver_licence_container p {
  border: 0;
  font-weight: 700;
}

.top_container {
  display: flex;
  background-color: #069edf;
  border-radius: 16px 16px 0px 0px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  justify-content: space-around;
  align-items: center;
}

.driving_licence_logo {
  width: 20%;
}

.bottom_container {
  display: flex;
  gap: 4rem;
  align-items: flex-start;
  padding: 1rem;
}

.left_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  gap: 2rem;
}

.left_container > div {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: center;
}

.driver_image,
.profile_picture_container {
  width: 140px;
  height: 160px;
}

/* Profile Picture  */
.profile_picture_container {
  border: 1px solid grey;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile_picture_container > form {
  position: relative;
  width: 100%;
  height: 100%;
}

.profile_picture_container {
  position: relative;
}

.profile_picture_container > img {
  width: 100%;
  height: 100%;
}

.profile_input {
  opacity: 1;
  width: 140px;
  height: 160px;
  position: absolute;

  z-index: 3;
}

/* .upload_button {
  z-index: 4;
} */

/* profile picture end  */

.right_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
}

.right_container label {
  font-size: 0.75rem;
}

.row_container {
  display: flex;
  width: 100%;
  gap: 6rem;
}
.gap_column_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.gap_column_container > p {
  font-size: 1rem;
}

.row_container > div {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.5rem;
}

.extra_main_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 0rem 0.25rem 0rem;
  gap: 1rem;
  margin-top: 0.5rem;
}

.extra_container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.left_extra_container,
.right_extra_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.left_extra_container > div,
.right_extra_container > div {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.extra_main_container label {
  font-weight: 600;
}

.button_container {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
  height: 100px;
}

.upload_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;
}

.upload_container > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid rgb(191, 191, 191);
  padding: 1rem;
}

.appended_files_extended_container {
  display: flex;
  flex-direction: column;
}

.appended_files_extended_container > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.append_main_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  padding: 1rem 0rem 0rem 0rem;
  gap: 0.5rem;
}

.append_main_container > button {
  padding: 0.5rem 0.75rem;
  outline: 0;
  border: 0;
  border-radius: 4px;
  background-color: var(--primary-color);
  color: white;
}

/* .append_main_container {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  border: 2px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  background-color: var(--primary-color);
  border-radius: 8px;
  width: 300px;
}

.append_main_container img {
  position: absolute;
  right: 16px;
  top: 16px;
}
.append_option_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}

.append_option_container button {
  padding: 0.5rem 1rem;
  outline: 0;
  border: 0;
  background-color: white;
} */

/* Media queries  */

@media screen and (max-width: 1350px) {
  .grid_headings,
  .grid_inputs {
    grid-template-columns: 175px 175px 175px 175px 175px;
  }

  .table_container {
    width: 900px;
  }
}

@media screen and (max-width: 1150px) {
  .table_container {
    display: flex;
    flex-direction: column;
    width: 600px;
  }

  .grid_headings,
  .grid_inputs {
    grid-template-columns: 100px 100px 100px 100px 100px;
  }

  .grid_headings span {
    font-size: 0.75em;
  }
  .grid_inputs input {
    width: 100%;

    font-size: 0.75em;
  }
}

@media screen and (max-width: 910px) {
  .main_modal_container {
    width: 600px;
  }
}

@media screen and (max-width: 896px) {
  .button_heading {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
  }

  .button_alignment_container {
    display: flex;
    gap: 1rem;
  }
}

@media screen and (max-width: 850px) {
  .main_modal_container {
    padding: 3rem 2rem;
  }
  .table_container {
    display: flex;
    flex-direction: column;
    width: 450px;
  }

  .grid_headings,
  .grid_inputs {
    grid-template-columns: 80px 80px 80px 80px 80px;
    align-items: center;
  }

  .grid_headings span {
    font-size: 0.5em;
  }
  .grid_inputs input {
    width: 100%;

    font-size: 0.75em;
  }
}

@media screen and (max-width: 600px) {
  .main_modal_container {
    padding: 3rem 0rem;
    width: 500px;
  }
  .wrap_container {
    overflow-y: scroll;
    padding: 0.5rem 0rem 0.25rem 0rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .top_container h3,
  .top_container h1,
  .top_container span {
    font-size: 0.75rem;
  }
  .extra_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
  }
  .right_container label,
  .left_container label,
  .left_container p {
    font-size: 0.5rem;
  }
  .right_container p {
    font-size: 0.5rem;
  }
  .row_container {
    display: flex;
    width: 100%;
    gap: 2rem;
  }
  .driving_licence_logo {
    width: 10%;
  }
  .extra_main_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 0rem 0.25rem 1rem;
    gap: 1rem;
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 400px) {
  .bottom_container {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    padding: 1rem;
  }
}

@media screen and (max-width: 500px) {
  .main_modal_container {
    padding: 3rem 0rem;
    width: 100vw;
  }
  .table_container {
    display: flex;
    flex-direction: column;
    width: 370px;
  }
  .grid_headings,
  .grid_inputs {
    grid-template-columns: 70px 70px 70px 70px 70px;
    align-items: center;
  }

  .form_input_label input {
    outline: 0;
    border: 0;
    height: 40px;
    padding: 0 1rem;
    background-color: white;
    width: 300px;
    border-radius: 16px 0px 0px 16px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  }
  .driver_image,
  .profile_picture_container {
    width: 105px;
    height: 120px;
  }
}
@media screen and (max-width: 410px) {
  .main_modal_container {
    padding: 3rem 0rem;
  }
  .table_container {
    display: flex;
    flex-direction: column;
    width: 320px;
  }
  .grid_headings,
  .grid_inputs {
    grid-template-columns: 60px 60px 60px 60px 60px;
    align-items: center;
  }

  .form_input_label input {
    outline: 0;
    border: 0;
    height: 40px;
    padding: 0 1rem;
    background-color: white;
    width: 250px;
    border-radius: 16px 0px 0px 16px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  }
}
