* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: "Sora", sans-serif;
}
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* --primary-color: #e87817; */
  --primary-color: #e84b01;
  --secondary-color: #ffe5ce;
  --heading-color: #502601;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrapper_container {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}

/* Not Logged In  */

.not_logged_in {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 2rem;
}

.not_logged_in button,
.button_get,
.button_add,
.button_all,
.button_general {
  width: fit-content;
  height: 40px;
  padding: 0 1rem;
  font-size: 1em;
}

.not_logged_in button {
  border: 0;
  outline: 0;
  background-color: #0c21c1;
  border-radius: 8px;
  color: white;
}

.button_get {
  font-weight: 500;
  justify-self: center;
  border: 0;
  outline: 0;
  background-color: var(--primary-color);
  border-radius: 0px 16px 16px 0px;
  color: white;
  transition: all 300ms ease;
}

.button_add,
.button_all {
  font-weight: 500;
  justify-self: center;
  border: 0;
  outline: 0;
  background-color: var(--primary-color);
  border-radius: 6px;
  color: white;
  transition: all 300ms ease;
}

.button_add {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.button_all:hover,
.button_add:hover,
.button_get:hover,
.button_general:hover {
  background-color: #b1590c;
}

.button_general {
  font-weight: 500;
  justify-self: center;
  border: 0;
  outline: 0;
  background-color: var(--primary-color);
  border-radius: 6px;
  color: white;
  transition: all 300ms ease;
}

.permission_tag {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
}
.permission_tag p {
  font-size: 1.5rem;
  color: var(--primary-color);
}

.permission_tag button {
  font-weight: 500;
  width: fit-content;
  height: 40px;
  padding: 0 1rem;
  font-size: 1em;
  justify-self: center;
  border: 0;
  outline: 0;
  background-color: var(--primary-color);
  border-radius: 6px;
  color: white;
  transition: all 300ms ease;
}

input[type="file"]::file-selector-button {
  border: 2px solid var(--primary-color);
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: var(--primary-color);
  transition: 1s;
  color: white;
}

/* Media Queries  */

@media screen and (max-width: 1210px) {
  .not_logged_in button,
  .button_get,
  .button_add,
  .button_all,
  .button_general {
    width: fit-content;
    height: 40px;
    padding: 0 0.5rem;
    font-size: 0.75em;
  }
  .button_get > img,
  .button_add > img,
  .button_all > img,
  .button_general > img {
    display: none;
  }
}

@media screen and (max-width: 760px) {
  .wrapper_container {
    flex-direction: column;
    gap: 2rem;
    height: 100vh;
  }
}

.button_get > img,
.button_add > img,
.button_all > img,
.button_general > img {
  display: none;
}
