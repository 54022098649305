.main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgb(255, 255, 255);
  position: relative;
  z-index: 1;
  overflow-y: scroll;
}

.back_button {
  color: var(--primary-color);
  outline: 0;
  border: 0;
  background-color: transparent;
  border-radius: 8px;
  padding: 0.5rem 2rem;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  letter-spacing: 0.1rem;
  transition: all 300ms ease;
}

.back_button:hover {
  color: white;
  background-color: var(--primary-color);
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2);
}

.button_alignment_container {
  display: flex;
  gap: 1rem;
}

/* .main_container::before {
  content: "";
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  background: url(../../Images/Register.jpeg);
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  opacity: 0.7;
  z-index: -1;
} */

.table_wrapper_container {
  display: flex;
  flex-direction: column;
  width: 60vw;
  align-items: center;
  gap: 1rem;
  background-color: black;
  padding: 2rem;
  border-radius: 0px;
  align-self: center;
  height: auto;
  height: 100vh;
}

.table_wrapper_container h1 {
  color: white;
}

.table_container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 2rem;
  height: 100vh;
  overflow-y: scroll;
}

.table_section_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.table_section_fields_container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.table_section_container h2 {
  color: var(--primary-color);
}

.table_wrapper_container button {
  width: fit-content;
  padding: 8px 16px;
  border-radius: 8px;
  border: 0;
  outline: 0;
  font-size: 1em;
  font-weight: 400;
  background-color: var(--primary-color);
  color: white;
  align-self: center;
}

.table_wrapper_container button:hover {
  background-color: #9d3201;
}

.table_container {
  background-color: white;
  border-radius: 8px;
}

.table_content {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: flex-start;
}

.table_content label {
  font-weight: 600;
}

.table_content input,
.table_content select,
.table_content textarea {
  padding: 0.5rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  outline: 0;
  border: 1px solid #f4f4f4;
  background-color: #f4f4f4;
}

.table_content input,
.table_content select,
.table_content textarea {
  width: 100%;
}

.table_content input:focus,
.table_content select:focus {
  outline: 1px solid var(--primary-color);
}

.file_container {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
}

.sview_file {
  padding: 0rem !important;
  font-size: 0.75rem !important;
  background-color: transparent !important;
  color: var(--primary-color) !important;
  font-size: 600 !important;
}

.table_file_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
}

.table_file_container h1 {
  color: black;
  font-size: 1.25em;
}

.table_file_container div {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}
.above_container,
.special_container,
.below_container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.button_container {
  display: flex;
  gap: 1rem;
  align-items: center;
}

/* Media queries  */

@media screen and (max-width: 770px) {
  .table_wrapper_container {
    width: 100vw;
    padding: 4rem;
  }
}
