.container,
.container ul,
.lower_container {
  display: flex;
  flex-direction: column;
}

.container {
  min-height: 100vh;
  max-height: 100vh;
  max-width: 200px;
  min-width: 200px;
  /* width: 200px; */
  width: 30%;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0rem 4rem 0rem;
  background-color: #ffffff;
  border-right: 1px solid rgb(221, 221, 221);
}

.nav_logo_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
}

.nav_main_logo {
  width: 50%;
}

.nav_comp_logo {
  height: 60px;
}
.container ul,
.lower_container {
  gap: 1.5rem;
  width: 100%;
}

.container ul button {
  outline: 0;
  border: 0;
  background-color: transparent;
  color: black;
  font-size: 1em;
  font-weight: 400;
}

hr {
  width: 100%;
}

.nav_container {
  display: flex;
  align-items: center;
  padding: 0.5rem 0rem;
  transition: all 300ms ease;
  width: 100%;
}
.bottom_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;
}
.bottom_container p {
  color: var(--primary-color);
}
.nav_child {
  display: flex;
  align-items: center;
  margin-left: 20%;
  gap: 0.75rem;
}

.nav_container:hover {
  background-color: #fee1c8;
}

.logOut_button {
  color: red;
  background-color: white;
  font-weight: 600;
  outline: 0;
  border: 0;
  width: 100%;
  padding: 0.75rem 0;
  transition: all 300ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.logOut_button:hover {
  color: white;
  background-color: red;
}

.mobile_nav_container {
  display: none;
}

/* Media queries  */

@media screen and (max-width: 770px) {
  .menu_container {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 0 2rem;
  }

  .company_logo {
    width: 70px;
  }

  .mobile_nav_container {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    padding: 1rem 0rem;
    gap: 2rem;
    z-index: 3;
    border-bottom: 1px solid grey;
    background-color: rgb(255, 255, 255);
  }

  .mobile_nav_container button {
    color: rgb(0, 0, 0);
    background-color: transparent;
    outline: 0;
    border: 0;
  }

  .nav_container {
    justify-content: center;
    padding: 1rem 0rem;
    transition: all 300ms ease;
  }

  .nav_container:hover {
    background-color: #fee1c8;
  }

  .nav_child {
    display: flex;
    align-items: flex-start;
    margin-left: 0%;
    gap: 3rem;
  }

  .container {
    display: none;
  }
}
