.main_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem 0 0 3rem;
  width: 100%;
  gap: 2rem;
}

.personal_container,
.company_update_container,
.company_update_container form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
}

.personal_container form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  /* width: 50%; */
}

.personal_container form button {
  width: 140px;
}

/* .label_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.2rem;
} */

.content_container {
  display: inline-grid;
  /* column-gap: 50px 50px; */
  align-items: center;
  grid-template-columns: 200px 300px 300px;
  /* gap: 0.5rem; */
}

.content_container label {
  color: rgb(103, 103, 103);
}

.content_email_container {
  display: flex;
  gap: 0.5rem;
}
.content_email_container p {
  width: 100%;
  font-size: 1em;
  /* border-bottom: 1px solid rgb(220, 220, 220); */
  border-bottom: 1px solid var(--primary-color);
  /* padding: 0.25rem 0.75rem; */
  border-radius: 2px;
}

.update_password {
  color: var(--primary-color);
  padding: 0.5rem 1rem;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  outline: 0;
  border: 0;
  background-color: transparent;
}

.main_modal_container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  z-index: 3;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 2rem;
  background-color: white;
  box-shadow: 0px 0px 8px rgb(0, 0, 0);
  margin: auto;
  border-radius: 8px;
  width: 700px;
  height: 300px;
}

.main_modal_container form {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
}

.main_modal_container .fields_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
}

.modal_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.modal_content label {
  margin-right: 16px;
}

.modal_button_container {
  display: flex;
  gap: 1rem;
}

/* Media queries  */

@media screen and (max-width: 1140px) {
  .main_modal_container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    z-index: 3;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 2rem;
    background-color: white;
    box-shadow: 0px 0px 8px rgb(0, 0, 0);
    margin: auto;
    border-radius: 8px;
    width: 350px;
    height: 300px;
  }
}
