.main_container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  gap: 2rem;
  padding: 2rem 0;
  flex: 1;
  padding: 2rem 1rem;
  overflow-y: hidden;
  z-index: 1;
  position: relative;
  background-color: white;
}

.button_heading {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.button_heading h2,
.form_input_label h2 {
  font-size: 1.4em;
  font-weight: 600;
  color: white;
}

.statusDropDownContainer select {
  padding: 0.5rem 0.25rem;
  width: 120px;
  border-radius: 4px;
  font-size: 1rem;
  outline: 0;
  color: #606060;
  border: 0;
}

/* .statusDropDownContainer select::selection {
  border: 0;
  color: white;
  background-color: var(--primary-color);
} */

.form_input_label {
  display: flex;
  /* flex-direction: column; */
  gap: 0rem;
  align-items: center;
}

.form_input_label input {
  outline: 0;
  border: 0;
  height: 40px;
  padding: 0 1rem;
  background-color: white;
  width: 400px;
  border-radius: 16px 0px 0px 16px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}

.table_wrapper_container {
  display: flex;
  align-items: center;
  height: 80%;
  border-radius: 8px;
  gap: 3rem;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  background-color: white;
  padding: 1rem;

  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}

.table_wrapper_container > h1 {
  /* color: rgb(255, 255, 255); */
  position: absolute;
  font-size: 1.5em;
  /* color: var(--primary-color); */
  color: black;
  top: 24px;
}
.table_wrapper_container hr {
  height: 1px;
  border-width: 0;
  width: 90%;
  background-color: #efefef;
  margin-left: auto;
  margin-right: auto;
}

.table_container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 80%;
  width: 100%;
  overflow-y: scroll;
  background-color: white;
  padding: 1rem;
  gap: 1rem;
  position: relative;
  border-radius: 8px;
  min-width: 0;
  margin-top: 16px;
}

.table_main_container {
  align-self: center;
}

.table_main_container button,
.excel_container button {
  position: absolute;
  left: 0;
  bottom: 16px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  padding: 8px 16px;
  border-radius: 8px;
  border: 0;
  outline: 0;
  font-size: 1em;
  font-weight: 400;
  background-color: var(--primary-color);
  color: white;
}

/* For table fields  */

.grid_headings,
.grid_inputs {
  gap: 0rem;
  display: inline-grid;
  grid-template-columns: 250px 250px 250px 100px;
}

.grid_inputs {
  padding: 0.75rem 0;
}

.status {
  text-transform: capitalize;
}

.grid_headings span {
  font-size: 1.25em;
  font-weight: 200;
  text-align: center;
  padding: 0.5rem 0;
  color: #606060;
  /* text-align: left; */
}

.heading {
  margin-bottom: 16px;
}

.grid_inputs input {
  width: 100%;
  /* padding: 0.4rem; */
  font-size: 1em;
  font-weight: 400;
  outline: 0;
  border-width: 0;
  text-align: center;
}

/* Modal Container  */

.main_modal_container {
  position: absolute;
  display: flex;
  z-index: 3;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 2rem;
  background-color: white;
  box-shadow: 0px 0px 8px rgb(0, 0, 0);
  margin: auto;
  border-radius: 8px;
  width: 700px;
  height: 700px;
}

/* .main_modal_container p {
  border: 0;
  font-weight: 700;
} */

.modal_container {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: scroll;
  align-items: center;
  width: 100%;
}

.modal_container > button {
  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;
}

.wrap_container {
  overflow-y: scroll;
  width: 100%;
  border: 0.5px solid black;
}

.licence_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  border: 1px solid black;
  /* background-color: var(--secondary-color); */
  background-color: #edf4ed;
}

.main_top_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border-bottom: 1px solid var(--primary-color); */
  background-color: #d2e4d6;
  gap: 0.25rem;
}
.main_top_container > p {
  font-size: 0.85rem;
}

.top_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;

  width: 100%;
  /* background-color: var(--primary-color); */
}

.equi_logo {
  width: 25%;
}

.title {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.title > label {
  font-weight: 600;
  font-size: 1rem;
  color: black;
}

.title > p {
  font-weight: 500;
  font-size: 1rem;
  color: black;
  background-color: #d2e4d6;
  letter-spacing: 0.25em;
}

.black_top_container {
  background-color: black;
  width: 100%;
  padding: 0.35rem;
}

.black_top_container p {
  color: white;
  text-align: center;
}

.desc_container {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.desc_container label {
  font-size: 0.75rem;
  font-weight: 400;
}

.desc_container p {
  font-size: 0.95rem;
  background-color: #edf4ed;
  letter-spacing: 0.25em;
  margin-left: 8px;
  font-weight: 600;
}

.licence_container > div > label {
  font-size: 1rem;
  font-weight: 600;
}

.container_1,
.container_2,
.container_3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
}

.container_1 div,
.container_2 div {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.button_container {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
  height: 100px;
}

/* Buttom container  */

.bottom_container {
  display: flex;
  height: 150px;
  width: 100%;
  border-top: 1px solid black;
}

.signature_container {
  border: 1px solid black;
  height: 100%;
}
.bottom_wrap_container {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.bottom_upper_container {
  display: flex;
  height: 90px;
  width: 100%;

  /* width: 100%; */
}

.bottom_upper_container label {
  font-size: 0.75rem;
  font-weight: 400;
}

.bottom_upper_container p {
  font-size: 0.95rem;
  background-color: #edf4ed;
  letter-spacing: 0.25em;
  font-weight: 600;
}
.upper_info_container {
  border: 1px solid black;
  padding: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
}

.upper_info_container label,
.upper_info_container p {
  text-align: center;
}

.bottom_right_container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.25em;
}

.bottom_right_container p {
  font-size: 0.75rem;
}

.container_3 {
  display: flex;
  width: 100%;
  padding: 1rem 0rem 0.25rem 0rem;
}

.container_3 div label {
  font-weight: 600;
}

.container_3_main {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.left_container,
.right_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.left_container div,
.right_container div {
  display: flex;
  gap: 0.5rem;
  padding: 0.25rem;
}

.button_alignment_container {
  display: flex;
  gap: 1rem;
}

.view_button {
  padding: 0.2rem 0.2rem !important;
  font-size: 0.75rem !important;
  background-color: transparent !important;
  color: var(--primary-color) !important;
  font-size: 600 !important;
  border-radius: 2px !important;
  outline: 0 !important;
  border: 0 !important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) !important;
}

.maintenance_container {
  display: flex;
  flex-direction: column;
  /* overflow-y: scroll; */
  width: 100%;
  padding: 1rem;
  gap: 1rem;
}

.maintenance_container div {
  display: flex;
  gap: 0.25rem;
}

.maintenance_container div p {
  font-weight: 600;
}

.upper_maintenacne_container {
  display: flex;
  gap: 0.25rem;
}

.upper_maintenacne_container_index {
  font-weight: 600;
}

/* Media queries  */

@media screen and (max-width: 1350px) {
  .grid_headings,
  .grid_inputs {
    grid-template-columns: 175px 175px 175px 175px;
  }

  .table_container {
    width: 900px;
  }
}

@media screen and (max-width: 1150px) {
  .table_container {
    display: flex;
    flex-direction: column;
    width: 600px;
  }

  .grid_headings,
  .grid_inputs {
    grid-template-columns: 150px 150px 150px 150px;
  }

  .statusDropDownContainer select {
    font-size: 0.75em;
  }

  .grid_headings span {
    font-size: 0.75em;
  }
  .grid_inputs input {
    width: 100%;

    font-size: 0.75em;
  }
}

@media screen and (max-width: 910px) {
  .main_modal_container {
    width: 600px;
  }
}

@media screen and (max-width: 896px) {
  .button_heading {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
  }

  .button_alignment_container {
    display: flex;
    gap: 1rem;
  }
}

@media screen and (max-width: 850px) {
  .main_modal_container {
    padding: 3rem 2rem;
  }
  .table_container {
    display: flex;
    flex-direction: column;
    width: 450px;
  }

  .statusDropDownContainer select {
    font-size: 0.5em;
    width: 75px;
  }

  .grid_headings,
  .grid_inputs {
    grid-template-columns: 100px 100px 100px 100px;
    align-items: center;
  }

  .grid_headings span {
    font-size: 0.5em;
  }
  .grid_inputs input {
    width: 100%;

    font-size: 0.75em;
  }
}

@media screen and (max-width: 600px) {
  .main_modal_container {
    padding: 3rem 0rem;
    width: 500px;
  }
  .wrap_container {
    overflow-y: scroll;
    padding: 0.5rem 0rem 0.25rem 0rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .top_container h3,
  .top_container h1,
  .top_container span {
    font-size: 0.75rem;
  }
  .extra_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
  }
  .right_container label,
  .left_container label,
  .left_container p {
    font-size: 0.75rem;
  }
  .container_3 {
    padding: 0 1rem;
  }
  .right_container p {
    font-size: 0.75rem;
  }
  .row_container {
    display: flex;
    width: 100%;
    gap: 2rem;
  }
  .driving_licence_logo {
    width: 10%;
  }
  .extra_main_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 0rem 0.25rem 1rem;
    gap: 1rem;
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 400px) {
  .bottom_container {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    padding: 1rem;
  }
}

@media screen and (max-width: 500px) {
  .container_3_main {
    flex-direction: column;
  }
  .desc_container label {
    font-size: 0.65rem;
    font-weight: 400;
  }

  .desc_container p {
    font-size: 0.5rem;
    background-color: #edf4ed;
    letter-spacing: 0.25em;
    margin-left: 8px;
    font-weight: 600;
  }
  .main_modal_container {
    padding: 3rem 0rem;
    width: 100vw;
  }
  .table_container {
    display: flex;
    flex-direction: column;
    width: 370px;
  }
  .statusDropDownContainer select {
    font-size: 0.5em;
    width: 75px;
  }

  .grid_headings,
  .grid_inputs {
    grid-template-columns: 100px 100px 100px 100px;
    align-items: center;
  }

  .form_input_label input {
    outline: 0;
    border: 0;
    height: 40px;
    padding: 0 1rem;
    background-color: white;
    width: 300px;
    border-radius: 16px 0px 0px 16px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  }
  .driver_image,
  .profile_picture_container {
    width: 105px;
    height: 120px;
  }
}
@media screen and (max-width: 410px) {
  .main_modal_container {
    padding: 3rem 0rem;
  }
  .table_container {
    display: flex;
    flex-direction: column;
    width: 320px;
  }
  .statusDropDownContainer select {
    font-size: 0.5em;
    width: 75px;
  }

  .grid_headings,
  .grid_inputs {
    grid-template-columns: 75px 75px 75px 75px;
    align-items: center;
  }

  .form_input_label input {
    outline: 0;
    border: 0;
    height: 40px;
    padding: 0 1rem;
    background-color: white;
    width: 250px;
    border-radius: 16px 0px 0px 16px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  }
}
